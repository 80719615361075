/**
 * Return the window width
 * @returns {number} The window width
 */
export const getWindowWidth = () => window.visualViewport?.width || window.innerWidth

/**
 * Tell if we have a mobile resolution or more
 *
 * @returns {boolean}
 */
export const isMobile = () => getWindowWidth() < 768

/**
 * Tell if we have a mobile resolution or more
 *
 * @returns {boolean}
 */
export const isTouch = () => getWindowWidth() < 1024

/**
 * Tell if we have a widescreen resolution or more
 *
 * @returns {boolean}
 */
export const isWidescreen = () => getWindowWidth() >= 1217
