export const DEFAULT_LOCALE = 'fr'

/**
 * @return {""|"en"|"it"|"es"}
 */
export function getCurrentLocale() {
  const locales = ['en', 'es', 'fr', 'it']

  for (let i = 0; i < locales.length; i += 1) {
    if (window.location.pathname.includes(`/${locales[i]}`)) return locales[i]
  }
  return DEFAULT_LOCALE
}

/**
 * @return {""|"/en"|"/it"|"/es"}
 */
export function getCurrentLocalePathPrefix() {
  const currentLocale = getCurrentLocale()

  return currentLocale === DEFAULT_LOCALE ? '' : `/${currentLocale}`
}
