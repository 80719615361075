import React from 'react'

const getFreeReturnModalConfig = (isPro) => {
  let title = 'Retour gratuit à réception'
  if (isPro) title = 'Retour gratuit 30 jours'

  const html = `
    Chaque transaction réalisée sur Campsider intègre une protection incluse qui vous couvre contre tous les éventuels problèmes ou insatisfactions liés à votre commande.
    <br/><br/>
    Si le produit n’est pas conforme à l’annonce, vous pouvez le retourner gratuitement, sans aucun frais.
    Vous serez intégralement remboursé (commande et frais de livraison).
    <br/>
    <br/>
    Pour déclencher le retour, vous pouvez nous contacter dans un <b>délai de {nbDays} jours à compter de la date de réception.</b>
    Notre équipe est disponible par mail (hello@campsider.com) et par téléphone (09 70 70 49 18).
    Toutes les conditions de retour sont détaillées sur <a href="https://support.campsider.com/hc/fr/articles/6645761066781-Je-souhaite-annuler-retourner-ma-commande-comment-faire" target="_blank" class="is-underlined">ce lien</a>.
  `.replace('{nbDays}', isPro ? '30' : '3')

  return {
    html,
    title,
    titleRightItem: <i className="icon--shield-filled has-text-reinsurance" />,
  }
}

const getBikeDeliveryModalConfig = () => ({
  html: `
        <img src="/build/images/product/delivery/bike.png" alt="Livraison vélo"/>
        <br />
        <br />
        <p>La livraison des vélos en vente sur Campsider a été minutieusement réfléchie pour vous garantir la meilleure expérience possible.</p>
        <br/>
        <strong>1/ Une livraison 100% sécurisée</strong>
        <p>
            Chaque livraison est assurée à 100% par Campsider contre la casse et le vol. Pour toute question ou retour,
            n’hésitez pas à contacter notre service client via l’adresse <a class="is-underlined" href="mailto:hello@campsider.com">hello@campsider.com</a>.
        </p>
        <br />
        <strong>2/ Vélo protégé et monté à 80%</strong>
        <p>
          Tous les vélos sont emballés dans un carton adapté avec les protections nécessaires au bon envoi des vélos afin de
          vous assurer une livraison sans accroc. Ils sont envoyés assemblés à 80% : il vous suffira de centrer le guidon et de monter la roue avant.
        </p>
        <br />
        <strong>3/ Délai de livraison</strong>
        <p>
          Le délai vous est indiqué lorsque vous sélectionnez votre mode de livraison. Une fois la commande validée, le
          délai de livraison est en moyenne de 3 à 5 jours ouvrés. Pour l’instant, nous ne livrons qu’en France métropolitaine.
        </p>
      `,
  title: 'Une livraison sur mesure',
  titleRightItem: <i className="icon--magic has-text-reinsurance" />,
})

const getBikeWarrantyModalConfig = (isPro) => ({
  html: `
        En complément du retour gratuit sous ${
          isPro ? '30' : '3'
        } jours, le vélo est vendu avec une <b>garantie réparation
        12 mois couvrant toutes les parties structurelles du vélo</b>. Si dans cette période l’acheteur rencontre un problème
        sur l'un de ces composants au cours des douze premiers mois, Campsider prend en charge la réparation du vélo.
        <br />
        <br />
        <ul>
            <li>
                S’il peut être réparé, un technicien interviendra gratuitement afin de réparer l'équipement et changer les pièces si besoin.
            </li>
            <li>
                S'il ne peut pas être réparé ou la réparation dépasse le coût de la machine, l’acheteur est remboursé du montant payé pour l'équipement.
            </li>
        </ul>
        <br />
        Vous souhaitez également protéger votre vélo contre le vol et la casse (dommages matériels dûs à un accident, des
        éléments naturels ou à un acte de vandalisme) ? Bénéficiez de notre assurance sur mesure, en ajoutant le produit au panier.
      `,
  title: 'Garantie Réparation',
  titleRightItem: <i className="icon--shield-filled has-text-reinsurance" />,
})

const getWarrantyModalConfig = (isPro) => ({
  html: `
        En complément du retour gratuit sous ${
          isPro ? '30' : '3'
        } jours, vous bénéficiez d’une <b>garantie 12 mois</b> couvrant tout défaut de fabrication, apparent ou vice caché.
        En application de cette garantie, le client pourra choisir entre la réparation ou le remplacement du bien.
        <br />
        <br />
        Attention, cette garantie s'applique sur l'<b>aspect fonctionnel du matériel</b> et non sur l'aspect esthétique.
      `,
  title: 'Garantie commerciale 12 mois',
  titleRightItem: <i className="icon--shield-filled has-text-reinsurance" />,
})

const getCyclistSizeModalConfig = () => ({
  html: `
      Notre recommandation de taille est basée sur les données officielles transmises par chaque marque de vélo.
      Si plusieurs tailles vous conviennent, il est conseillé de choisir le modèle le plus petit car il sera toujours possible de relever la selle.
      <br/>
      <br/>
      Vous pouvez également lire <a href="https://support.campsider.com/hc/fr/sections/20728777095325-Guides-d-achat" target="_blank" class="is-underlined has-text-weight-medium">nos guides de taille</a> ou bien échanger avec l’un de nos <b>experts cycles disponibles du lundi au samedi, de 10h à 19h au 09 70 70 49 18</b>.
      `,
  title: 'Taille du vélo',
  titleRightItem: <i className="icon--mesure has-text-primary" style={{ fontSize: '34px' }} />,
})

export {
  getFreeReturnModalConfig,
  getBikeDeliveryModalConfig,
  getBikeWarrantyModalConfig,
  getWarrantyModalConfig,
  getCyclistSizeModalConfig,
}
