/**
 * Convert a string to his trimmed lowercase version without accent
 * @see https://javascriptf1.com/snippet/remove-accents-from-a-string-in-javascript
 * @param {string} value
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const toLowerCaseWithoutAccent = (value = '') =>
  value
    .trim()
    .normalize('NFD') // transform accentued chars into two consecutive characters. eg :  è become `e
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

/**
 * Slugify a string (lowercase, remove all accent, replace space by hyphen
 *
 * @param value
 * @returns {string}
 */
export const slugify = (value) =>
  toLowerCaseWithoutAccent(value)
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/-+/g, '-') // Replace multiple - with single -

/**
 * Capitalize first letter of each word in a sentence
 *
 * @param {string} string
 * @returns {string}
 */
export const ucFirst = (string) => `${string[0].toUpperCase()}${string.slice(1)}`

export const obfuscate = (value, startObfuscateAtChar, nbObfuscatedChars) => {
  const startAt = startObfuscateAtChar || 0
  const nbChar = nbObfuscatedChars || 4
  const obfuscated = '*'.repeat(nbChar)
  return `${value.slice(0, startAt)}${obfuscated}${value.slice(startAt + nbChar)}`
}

export const replaceDotByComma = (value) => (value ? value.toString().replace('.', ',') : value)
