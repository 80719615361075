import { isMobile } from './responsive'

const isAdvisorOnline = () => {
  const now = new Date()
  const day = now.getDay()
  const hour = now.getHours()
  const isSunday = day === 0
  const isWorkingHours = hour >= 9 && hour <= 18

  return !isSunday && isWorkingHours
}

const showAdvisorWidget = () => {
  const advisorWidget = document.getElementById('advisor-widget-fixed-container')
  if (!advisorWidget) return
  advisorWidget.classList.remove('advisor-widget-fixed-container--hidden')
}

const hideAdvisorWidget = () => {
  const advisorWidget = document.getElementById('advisor-widget-fixed-container')
  if (!advisorWidget) return

  setTimeout(() => {
    advisorWidget.classList.add('advisor-widget-fixed-container--hidden')
  }, 200)
}

const moveMobileVersionSlightlyLeft = () => {
  const advisorWidget = document.getElementById('advisor-widget-fixed-container')
  if (!advisorWidget) return

  if (isMobile() && document.querySelector('.catalog-floating-cta__scroll-top')) {
    advisorWidget.classList.add('advisor-widget-fixed-container--catalog')
  } else advisorWidget.classList.remove('advisor-widget-fixed-container--catalog')
}

export { isAdvisorOnline, hideAdvisorWidget, showAdvisorWidget, moveMobileVersionSlightlyLeft }
